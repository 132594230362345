import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import React, { useState } from "react"

const AdCard = ({ad, setLightboxIndx, setLightboxOpen, indx}) => {
    const [open, setOpen] = useState(false);

    return (
    <li className="ad-card">

        <div className={`ad-card-container ${open ? 'ad-card-container-flipped' : ''}`}>
            <a className="ad-card-img" href={getSrc(ad.img)} onClick={(e) =>{
                e.preventDefault();
                setLightboxIndx(indx);
                setLightboxOpen(true);
            }}>
                <GatsbyImage image={getImage(ad.img)} layout="fullWidth" className="w-full block pixelate" alt={ad.alt} quality={75}  />
            </a>


            <div id={`ad-details-${indx}`} className="ad-card-details">
                <div className="ad-card-details-inner-wrap">
                    {ad?.details?.description ?
                        <p className="pb-6 mb-6 border-b border-gray-600">{ad.details.description}</p>
                        : null
                    }

                    {ad?.details?.artists && ad.details.artists.length ?
                        <>
                            <h4>Artists featured</h4>
                            <ul>{ad.details.artists.map((x, a_indx) => (
                                <li key={a_indx}>{x}</li> 
                            ))}
                            </ul>  
                        </>
                        : null
                    }
                    
                    {ad?.details?.guitars && ad.details.guitars.length ?
                        <>
                            <h4>Guitars featured</h4>
                            <ul>{ad.details.guitars.map((x, g_indx) => (
                                <li key={g_indx}>{x}</li> 
                            ))}
                            </ul>  
                        </>
                        : null
                    }

                    {ad?.details?.appears && ad.details.appears.length ?
                        <>
                            <h4>Appears</h4>
                            <ul>{ad.details.appears.map((x, ap_indx) => (
                                <li key={ap_indx}>{x}</li> 
                            ))}
                            </ul>  
                        </>
                        : null
                    }
                </div>
            </div>
        </div>


        
        <h3 className="font-serif font-bold text-xl mt-4 mb-2">{ad.title}</h3>
        <a href={`#ad-details-${indx}`} className="details-toggle" aria-controls={`ad-details-${indx}`} aria-expanded={open} onClick={(e) => {
            e.preventDefault();
            setOpen(!open);
        }}>{open ? 'Close details' : 'Details'}</a>
        
    </li>
    );
}

export default AdCard
