import React, { useState } from "react"
import { StaticImage, getSrcSet } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import AdCard from "../components/AdCard"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/Layout"
import Lightbox from "react-image-lightbox"
import Seo from "../components/seo"
import { getLargestSrc } from "../utils/utils"

const AdsPage = ({pageContext}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const [lightbox_indx, setLightboxIndx] = useState(0);
  const [lightbox_open, setLightboxOpen] = useState(false);

  let crumbs_mod = crumbs.slice(0);

  crumbs_mod.splice(-1, 0, {pathname: '/media', crumbLabel: 'Media'});


  const data = useStaticQuery(graphql`
  query MyQuery {
    mediaAdsYaml {
      ads {
        alt
        title
        year
        details {
          appears
          artists
          description
          guitars
        }
        img {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
  `);

  

  const ads = data.mediaAdsYaml.ads;

  const images = ads.map(x => x.img);
  
  
  return (
    <Layout >
      <Seo title="Talman ads" />

      <div className="relative max-h-60vh max-w-1920 bg-black mx-auto lg:flex lg:items-center">
          <div className="relative z-0 w-full max-h-60vh overflow-hidden">
            <StaticImage layout="fullWidth" className="h-full block" src="../assets/images/ads/ads-hero.jpg" alt="original 1994 Talman magazine ad next to a '94 TV750 Talman" quality={75}  />
          </div>

          <h1 className="absolute z-10 page-heading pl-5 lg:pl-12 bottom-0 left-0  leading-3">Talman<br /> ads</h1>
          <div className="absolute z-0 left-0 top-0 w-full h-full bg-gradient-to-t from-black via-transparent " />
      </div>
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12 pt-0 lg:pt-0">
        <Breadcrumb crumbs={crumbs_mod} crumbLabel="Ads" crumbSeparator="»" />

        <h2 className="section-heading">1994</h2>
        <ul className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-x-4 gap-y-8">
          {ads.map((x, indx) => {
            if (x.year === 1994) {
              return <AdCard key={`_${indx}_${x.title}`} ad={x} indx={indx} setLightboxIndx={setLightboxIndx} setLightboxOpen={setLightboxOpen} />;
            }

            return null;
          })}
        </ul>
        
        {lightbox_open ? 
              <Lightbox
                mainSrc={getLargestSrc(getSrcSet(images[lightbox_indx]))}
                nextSrc={getLargestSrc(getSrcSet(images[(lightbox_indx + 1) % images.length]))}
                prevSrc={getLargestSrc(getSrcSet(images[(lightbox_indx + images.length - 1) % images.length]))}
                onCloseRequest={() => setLightboxOpen(false)}
                onMovePrevRequest={() =>
                  setLightboxIndx((lightbox_indx + images.length - 1) % images.length)
                }
                onMoveNextRequest={() =>
                  setLightboxIndx((lightbox_indx + 1) % images.length)
                }
              />
              : null 
            }

        <section className="flex flex-col items-center">
          <h2 className="text-2xl mt-28">more coming soon...</h2>
        </section>
        
      </div>
    </Layout>
  )
}

export default AdsPage